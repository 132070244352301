<template>
  <router-view />
</template>

<script>
export default {
  components: {},
  data: () => ({}),
  computed: {},
  destroyed() {},
  methods: {},
};
</script>

<style></style>
